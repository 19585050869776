import React from 'react'
import logo from '../Assets/m-l.JPG'

const Header = () => {
  return (
    <div>
      <header>
          <img src={logo} alt="" />
      </header>
    </div>
  )
}

export default Header